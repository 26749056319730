<template>
  <div class="usual">
    <div class="usualtop">
      <span>常用设置</span>
      <span @click="firstbtn">设置</span>
    </div>
    <div class="usualmain">
      <div>
        <p>总数：</p>
        <p>{{ allnum }}</p>
      </div>
      <!-- <div>
        <p>变量：</p>
        <p>{{ changenum }}</p>
      </div> -->
      <div>
        <p>换液数：</p>
        <p>{{ exitnum }}</p>
      </div>
      <div>
        <p>泵1: </p>
        <p>{{ beng1 }}%</p>
      </div>
      <div>
        <p>泵2: </p>
        <p>{{ beng2 }}%</p>
      </div>
      <div>
        <p>风扇：</p>
        <p v-if="fan == 1">开启</p>
        <p v-if="fan == 0">关闭</p>
      </div>

    </div>
    <el-dialog
      title="常用设置"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="usuadit">
        <!--  v-model="input" -->
        <div>总数：</div>
        <el-input v-model="input" style="width: 30%" @keyup.enter.native="setbtn"></el-input>
        <div ref="fanwei1">输入范围0-10000</div>
      </div>
      <!-- <div class="usuadit">
        <div>变量：</div>
        <el-input v-model="input1" style="width: 30%" @keyup.enter.native="setbtn"></el-input>
        <div ref="fanwei2">输入范围0-1000</div>
      </div> -->
      <div class="usuadit">
        <div>换液数：</div>
        <el-input v-model="input2" style="width: 30%" @keyup.enter.native="setbtn"></el-input>
        <div ref="fanwei3">输入范围0-1000</div>
      </div>
      <div class="usuadit">
        <div>泵1：</div>
        <el-input v-model="input3" style="width: 30%" @keyup.enter.native="setbtn"></el-input>
        <div ref="fanwei4">输入范围0.1-100</div>
      </div>
      <div class="usuadit">
        <div>泵2：</div>
        <el-input v-model="input4" style="width: 30%" @keyup.enter.native="setbtn"></el-input>
        <div ref="fanwei5">输入范围0.1-100</div>
      </div>
      <div class="usuadit">
        <div>风扇：</div>
        <el-switch
          v-model="fan"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
          :width="50"
        >
        </el-switch>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setbtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { details } from "../../api/detail/details";
import { changyong } from "../../api/equip/device";
export default {
  name: "usetime",
  props: {},
  data() {
    return {
      allnum: "10000",
      changenum: "900",
      exitnum: "621",
      beng1: "88",
      beng2: "60",
      dialogVisible: false,
      input: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      sn: "",
      fan:'',
    };
  },
  created() {
    details(this.$store.state.sheid).then((res) => {
      console.log("常用设置");
      console.log(res.general[0]);
      this.sn = res.details[0].sn;
      this.allnum = res.general[0].zns;
      this.changenum = res.general[0].nswc;
      this.exitnum = res.general[0].hxns;
      this.beng1 = res.general[0].jyb;
      this.beng2 = res.general[0].jybs;
      this.fan = res.general[0].fan
    });
  },
  methods: {
    setbtn() {
      this.$refs.fanwei1.style.color = "#adadad";
      // this.$refs.fanwei2.style.color = "#adadad";
      this.$refs.fanwei3.style.color = "#adadad";
      this.$refs.fanwei4.style.color = "#adadad";
      this.$refs.fanwei5.style.color = "#adadad";
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.input)) {
        this.$refs.fanwei1.style.color = "red";
      }
      // if (!numRe.test(this.input1)) {
      //   this.$refs.fanwei2.style.color = "red";
      // }
      if (!numRe.test(this.input2)) {
        this.$refs.fanwei3.style.color = "red";
        console.log("123156411111111111sassssssssssssss");
      }
      if (!numRe.test(parseInt(this.input3))) {
        this.$refs.fanwei4.style.color = "red";
        console.log("1231564");
      }
      if (!numRe.test(parseInt(this.input4))) {
        this.$refs.fanwei5.style.color = "red";
        console.log("1231564????????????????");
      }
      if (this.input > 10000 || this.input < 0) {
        this.$refs.fanwei1.style.color = "red";
      }
      // if (this.input1 > 1000 || this.input1 < 0) {
      //   this.$refs.fanwei2.style.color = "red";
      // }
      if (this.input2 > 1000 || this.input2 < 0) {
        this.$refs.fanwei3.style.color = "red";
      }
      if (this.input3 > 100 || this.input3 < 0.1) {
        this.$refs.fanwei4.style.color = "red";
        console.log("123165464646541565555555555555555555555555");
      }
      if (this.input4 > 100 || this.input4 < 0.1) {
        this.$refs.fanwei5.style.color = "red";
      }
      if (
        this.input <= 10000 &&
        this.input >= 0 &&
        // this.input1 <= 1000 &&
        // this.input1 >= 0 &&
        this.input2 <= 1000 &&
        this.input2 >= 0 &&
        this.input3 <= 100 &&
        this.input4 <= 100 &&
        this.input4 >= 0.1 &&
        this.input3 >= 0.1
      ) {
        // this.allnum = JSON.parse(JSON.stringify(this.input));
        // this.changenum = JSON.parse(JSON.stringify(this.input1));
        // this.exitnum = JSON.parse(JSON.stringify(this.input2));
        // this.beng1 = JSON.parse(JSON.stringify(this.input3));
        // this.beng2 = JSON.parse(JSON.stringify(this.input4));
        let str = {
          zns: this.input,
          // nswc: this.input1,
          nswc: 0,
          hxns: this.input2,
          jyb: this.input3,
          jybs: this.input4,
          sn: this.sn,
          fan:this.fan
        };
        console.log(str);
        changyong(str).then((res) => {
          
          console.log(res);
          if (res.resutlt == 0) {
            details(this.$store.state.sheid).then((res) => {
              console.log("常用设置");
              console.log(res.general[0]);
              this.sn = res.details[0].sn;
              this.allnum = res.general[0].zns;
              this.changenum = res.general[0].nswc;
              this.exitnum = res.general[0].hxns;
              this.beng1 = res.general[0].jyb;
              this.beng2 = res.general[0].jybs;
            });
            this.$message({
              message: "恭喜你，设置成功！",
              type: "success",
            });
            this.dialogVisible = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    firstbtn() {
      this.dialogVisible = true;
      this.input = JSON.parse(JSON.stringify(this.allnum));
      // this.input1 = JSON.parse(JSON.stringify(this.changenum));
      this.input2 = JSON.parse(JSON.stringify(this.exitnum));
      this.input3 = JSON.parse(JSON.stringify(this.beng1));
      this.input4 = JSON.parse(JSON.stringify(this.beng2));
      if (this.$refs.fanwei1) {
        this.$refs.fanwei1.style.color = "#adadad";
       // this.$refs.fanwei2.style.color = "#adadad";
        this.$refs.fanwei3.style.color = "#adadad";
        this.$refs.fanwei4.style.color = "#adadad";
        this.$refs.fanwei5.style.color = "#adadad";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.usual {
  height: 413px;
  background: white;
  padding: 40px;
  .usualtop {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 24px;
      font-weight: 600;
    }
    span:nth-of-type(2) {
      font-weight: 400;
      color: rgba(31, 109, 249, 100);
      cursor: pointer;
    }
  }
  .usualmain {
    display: flex;
    padding: 30px;
    margin-top: 30px;
    div {
      width: 20%;
      font-size: 24px;
    }
  }
  .usuadit {
    display: flex;
    align-items: center;
    margin-top: 20px;
    /deep/.el-input__inner {
      font-size: 20px;
    }
    div {
      min-width: 96px;
      font-size: 24px;
      text-align: right;
    }
    div:nth-of-type(3) {
      color: rgba(173, 173, 173, 100);
      margin-left: 10px;
    }
  }
  /deep/.el-dialog__title {
    font-size: 28px;
    font-weight: 600;
  }
  /deep/.el-button {
    font-size: 20px;
  }
}
</style>
