<template>
  <div class="settime">
    <div class="settop" @click="play">修改</div>
    <div class="setmain">
      <div
        v-for="(item, index) in cheng"
        :key="index"
        @click="change(item, index)"
        ref="tibox"
      >
        {{ item.Stime }}:{{ item.Ftime }}
      </div>
    </div>
    <el-dialog
      title="定时启动设置"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="addtime">
        <div style="height: 200px; display: flex; justify-content: center">
          <div
            class="longtime"
            style="width: 100px; line-height: 50px; font-size: 32px"
          >
            <div style="text-align: center; height: 50px">
              <i
                class="el-icon-arrow-up"
                @click="jia"
                style="cursor: pointer"
              ></i>
            </div>
            <div style="position: relative">
            <!-- 时 -->
              <el-input-number
                v-model="num"
                :min="0"
                :max="23"
                label="描述文字"
                :controls="false"
                style="width: 100px"
                @keyup.enter.native ="btncun"
              ></el-input-number>
              <span style="position: absolute; margin-left: 23px">:</span>
            </div>
            <div style="text-align: center; height: 50px">
              <i
                class="el-icon-arrow-down"
                @click="jian"
                style="cursor: pointer"
              ></i>
            </div>
          </div>
          <div style="width: 50px"></div>
          <div
            class="longtime"
            style="width: 100px; line-height: 50px; font-size: 32px"
          >
            <div style="text-align: center; height: 50px">
              <i
                class="el-icon-arrow-up"
                @click="jia1"
                style="cursor: pointer"
              ></i>
            </div>
            <!-- 分 -->
            <el-input-number
              v-model="num1"
              :min="0"
              :max="59"
              label="描述文字"
              :controls="false"
              style="width: 100px"
              @keyup.enter.native ="btncun"
            ></el-input-number>
            <div style="text-align: center; height: 50px">
              <i
                class="el-icon-arrow-down"
                @click="jian1"
                style="cursor: pointer"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="btncun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { details } from "../../api/detail/details";
import { dingshi } from "../../api/equip/device";
export default {
  name: "settime",
  data() {
    return {
      num: "",
      num1: "",
      innerVisible: false,
      cheng: [],
      shibai: [],
      sectime: "",
      dialogVisible: false,
      dialogVisible1: "",
      msg: true,
      fname: "",
      sname: "",
      status: false,
      sn: "",
    };
  },
  created() {
    details(this.$store.state.sheid).then((res) => {
      console.log("定时启动设置");
      this.cheng = res.timing;
      this.sn = res.details[0].sn;
      console.log(res)
      // 格式化获得的时间
      for (let i = 0; i < this.cheng.length; i++) {
        if (this.cheng[i].Stime < 10) {
          this.cheng[i].Stime = "0" + this.cheng[i].Stime;
        }
        if (this.cheng[i].Ftime < 10) {
          this.cheng[i].Ftime = "0" + this.cheng[i].Ftime;
        }
      }
      console.log(this.cheng);
    });
  },
  methods: {
    play() {
      if (this.status == false) {
        this.$message.error("请选择一个时间进行修改");
      } else {
        this.dialogVisible = true;
      }
    },
    btncun() {
      console.log(this.fname);
      console.log(this.num);
      console.log(this.num1);
      console.log("保存成功");
      let str = {
        Fname: this.fname,
        Stime: this.num,
        Ftime: this.num1,
        sn: this.sn,
      };
      dingshi(str).then((res) => {
        console.log(res);
        if (res.resutlt == 0) {
          details(this.$store.state.sheid).then((res) => {
            console.log("定时启动设置");
            this.cheng = res.timing;
            this.sn = res.details[0].sn;
            for (let i = 0; i < this.cheng.length; i++) {
              if (this.cheng[i].Stime < 10) {
                this.cheng[i].Stime = "0" + this.cheng[i].Stime;
              }
              if (this.cheng[i].Ftime < 10) {
                this.cheng[i].Ftime = "0" + this.cheng[i].Ftime;
              }
            }
            // console.log(this.cheng);
          });
          this.$message.success("修改成功");
          this.status = false;
          for (let i = 0; i < this.cheng.length; i++) {
            this.$refs.tibox[i].style.color = "black";
          }
          this.dialogVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
      // this.$message({
      //   message: "添加定时启动时间成功！",
      //   type: "success",
      // });
    },
    // 加减
    jia() {
      this.num++;
    },
    jia1() {
      this.num1++;
    },
    jian() {
      this.num--;
    },
    jian1() {
      this.num1--;
    },
    change(item, index) {
      console.log(item);
      
      // 如果已点击的时间为红色，清除红色                      
      if (this.$refs.tibox[index].style.color == "red") {
        for (let i = 0; i < this.cheng.length; i++) {
          this.$refs.tibox[i].style.color = "black";
        }
        this.$refs.tibox[index].style.color = "black";
        this.num = "";
        this.num1 = "";
        this.sname = 0;
        this.fname = 0;
        this.status = false;
      } else {
        // 给选择的时间段红色样式(先给所有的黑色 再给选中的时间段红色)
        for (let i = 0; i < this.cheng.length; i++) {
          this.$refs.tibox[i].style.color = "black";
        }
        this.$refs.tibox[index].style.color = "red";
        this.num = parseInt(item.Stime);
        this.num1 = parseInt(item.Ftime);
        this.sname = item.Sname;
        this.fname = index + 1;
        // 先给状态设置为false 当有时间段为红色设置为true，即可打开修改时间弹窗
        this.status = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  box-sizing: border-box;
}
.settime {
  height: 413px;
  background: white;
  padding: 30px;
  .settop {
    text-align: right;
    font-size: 24px;
    color: #6e6ff9;
    cursor: pointer;
    margin-right: 15px;
  }
  .setmain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 300px;
    align-content: flex-start;
    div {
      font-size: 24px;
      width: 78px;
      height: 40px;
      margin-right: 50px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  /deep/.el-dialog__title {
    font-weight: 600;
    font-size: 24px;
  }
  .addnew {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    div {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: #1f6df9;
      color: white;
      font-size: 14px;
      text-align: center;
      margin-right: 5px;
      span {
        color: white;
      }
      cursor: pointer;
    }
    span {
      color: #1f97fb;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .addmain {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    div {
      width: 33%;
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 24px;
    }
    .setdel {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: red;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-left: 40px;
      span {
        color: white;
        font-size: 12px;
      }
    }
  }
  /deep/.el-input-number {
    width: 100px;
  }
  /deep/.el-button {
    font-size: 20px;
  }
}
</style>
