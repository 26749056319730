<template>
  <!-- 泵余量 -->
  <div class="remaining">
    <!-- 水泵一 -->
    <div class="first">
      <div class="firstleft">
        <div class="remtop">
          <p>泵1</p>
          <el-button type="info" round class="retbtn" @click="rechong">重置</el-button>
        </div>
        <div class="remmain">
          <div v-if="status1">
            余量状态：<span style="color: #55cdbb">OK</span>
          </div>
          <div v-if="status1 == false">
            余量状态：<span style="color: #ff1313">NO</span>
          </div>
          <div>预计余量：{{ remlist.bsurplus }}</div>
          <div>
            设定量：{{ remlist.pumpll }}
            <span class="she" @click="open">设置</span>
          </div>
        </div>
      </div>
      <div class="newbox">
        <img :src="img0" alt="" v-if="this.bai1 == 0" />
        <img
          :src="img1"
          alt=""
          v-if="this.bai1 > 0 && this.bai1 < 20 && this.status1 == true"
        />
        <img
          :src="img2"
          alt=""
          v-if="this.bai1 >= 20 && this.bai1 < 30 && this.status1 == true"
        />
        <img
          :src="img3"
          alt=""
          v-if="this.bai1 >= 30 && this.bai1 < 40 && this.status1 == true"
        />
        <img
          :src="img4"
          alt=""
          v-if="this.bai1 >= 40 && this.bai1 < 50 && this.status1 == true"
        />
        <img
          :src="img5"
          alt=""
          v-if="this.bai1 >= 50 && this.bai1 < 60 && this.status1 == true"
        />
        <img
          :src="img6"
          alt=""
          v-if="this.bai1 >= 60 && this.bai1 < 70 && this.status1 == true"
        />
        <img
          :src="img7"
          alt=""
          v-if="this.bai1 >= 70 && this.bai1 < 80 && this.status1 == true"
        />
        <img
          :src="img8"
          alt=""
          v-if="this.bai1 >= 80 && this.bai1 < 90 && this.status1 == true"
        />
        <img
          :src="img9"
          alt=""
          v-if="this.bai1 >= 90 && this.bai1 < 100 && this.status1 == true"
        />
        <img
          :src="img10"
          alt=""
          v-if="this.bai1 >= 100 && this.status1 == true"
        />
        <img
          :src="img11"
          alt=""
          v-if="this.bai1 > 0 && this.bai1 < 20 && this.status1 == false"
        />
        <img
          :src="img12"
          alt=""
          v-if="this.bai1 >= 20 && this.bai1 < 30 && this.status1 == false"
        />
        <img
          :src="img13"
          alt=""
          v-if="this.bai1 >= 30 && this.bai1 < 40 && this.status1 == false"
        />
        <img
          :src="img14"
          alt=""
          v-if="this.bai1 >= 40 && this.bai1 < 50 && this.status1 == false"
        />
        <img
          :src="img15"
          alt=""
          v-if="this.bai1 >= 50 && this.bai1 < 60 && this.status1 == false"
        />
        <img
          :src="img16"
          alt=""
          v-if="this.bai1 >= 60 && this.bai1 < 70 && this.status1 == false"
        />
        <img
          :src="img17"
          alt=""
          v-if="this.bai1 >= 70 && this.bai1 < 80 && this.status1 == false"
        />
        <img
          :src="img18"
          alt=""
          v-if="this.bai1 >= 80 && this.bai1 < 90 && this.status1 == false"
        />
        <img
          :src="img19"
          alt=""
          v-if="this.bai1 >= 90 && this.bai1 < 100 && this.status1 == false"
        />
        <img
          :src="img20"
          alt=""
          v-if="this.bai1 >= 100 && this.status1 == false"
        />
        <div>{{ bai1 }}%</div>
      </div>
    </div>
    <!-- 水泵二 -->
    <div class="first">
      <div class="firstleft">
        <div class="remtop">
          <p>泵2</p>
          <el-button type="info" round class="retbtn" @click="rechong1">重置</el-button>
        </div>
        <div class="remmain">
          <div v-show="status2">
            余量状态：<span style="color: #55cdbb">OK</span>
          </div>
          <div v-show="status2 == false">
            余量状态：<span style="color: #ff1313">NO</span>
          </div>
          <div>预计余量：{{ remlist.bsurpluss }}</div>
          <div>
            设定量：{{ remlist.pumplls }}
            <span class="she" @click="open1">设置</span>
          </div>
        </div>
      </div>
      <div class="newbox">
        <img :src="img0" alt="" v-if="this.bai2 == 0" />
        <img
          :src="img1"
          alt=""
          v-if="this.bai2 > 0 && this.bai2 < 20 && this.status2 == true"
        />
        <img
          :src="img2"
          alt=""
          v-if="this.bai2 >= 20 && this.bai2 < 30 && this.status2 == true"
        />
        <img
          :src="img3"
          alt=""
          v-if="this.bai2 >= 30 && this.bai2 < 40 && this.status2 == true"
        />
        <img
          :src="img4"
          alt=""
          v-if="this.bai2 >= 40 && this.bai2 < 50 && this.status2 == true"
        />
        <img
          :src="img5"
          alt=""
          v-if="this.bai2 >= 50 && this.bai2 < 60 && this.status2 == true"
        />
        <img
          :src="img6"
          alt=""
          v-if="this.bai2 >= 60 && this.bai2 < 70 && this.status2 == true"
        />
        <img
          :src="img7"
          alt=""
          v-if="this.bai2 >= 70 && this.bai2 < 80 && this.status2 == true"
        />
        <img
          :src="img8"
          alt=""
          v-if="this.bai2 >= 80 && this.bai2 < 90 && this.status2 == true"
        />
        <img
          :src="img9"
          alt=""
          v-if="this.bai2 >= 90 && this.bai2 < 100 && this.status2 == true"
        />
        <img
          :src="img10"
          alt=""
          v-if="this.bai2 == 100 && this.status2 == true"
        />
        <img
          :src="img11"
          alt=""
          v-if="this.bai2 > 0 && this.bai2 < 20 && this.status2 == false"
        />
        <img
          :src="img12"
          alt=""
          v-if="this.bai2 >= 20 && this.bai2 < 30 && this.status2 == false"
        />
        <img
          :src="img13"
          alt=""
          v-if="this.bai2 >= 30 && this.bai2 < 40 && this.status2 == false"
        />
        <img
          :src="img14"
          alt=""
          v-if="this.bai2 >= 40 && this.bai2 < 50 && this.status2 == false"
        />
        <img
          :src="img15"
          alt=""
          v-if="this.bai2 >= 50 && this.bai2 < 60 && this.status2 == false"
        />
        <img
          :src="img16"
          alt=""
          v-if="this.bai2 >= 60 && this.bai2 < 70 && this.status2 == false"
        />
        <img
          :src="img17"
          alt=""
          v-if="this.bai2 >= 70 && this.bai2 < 80 && this.status2 == false"
        />
        <img
          :src="img18"
          alt=""
          v-if="this.bai2 >= 80 && this.bai2 < 90 && this.status2 == false"
        />
        <img
          :src="img19"
          alt=""
          v-if="this.bai2 >= 90 && this.bai2 < 100 && this.status2 == false"
        />
        <img
          :src="img20"
          alt=""
          v-if="this.bai2 >= 100 && this.status2 == false"
        />
        <div>{{ bai2 }}%</div>
      </div>
    </div>
    <!-- 设置泵1 -->
    <el-dialog
      title="设定量设置"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <span style="font-size: 24px; margin-bottom: 20px"
        >请输入泵1的设定量</span
      >
      <br />
      <!-- v-model="sheding1" -->
      <el-input
        v-model="sheding1"
        style="width: 200px; margin-top: 30px"
        @keyup.enter.native="firsure"
      ></el-input>
      <span class="fanwei" ref="fanwei1">范围0-1000</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="firsure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置泵2 -->
    <el-dialog
      title="设定量设置"
      :visible.sync="dialogVisible1"
      width="600px"
      :close-on-click-modal="false"
    >
      <span style="font-size: 24px">请输入泵2的设定量</span> <br />
      <el-input
        v-model="sheding2"
        style="width: 200px; margin-top: 30px"
        @keyup.enter.native="secsure"
      ></el-input>
      <span class="fanwei" ref="fanwei2">范围0-1000</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="secsure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { details } from "../../api/detail/details";
import { deviceEdit } from "../../api/equip/device";
export default {
  name: "remaining",
  props: {},
  data() {
    return {
      status1: true,
      status2: false,
      dialogVisible: false,
      dialogVisible1: false,
      //   设置
      sheding1: "1000",
      sheding2: "1000",
      sheding22: "1000",
      sheding11: "1000",
      img0: require("../../assets/shuibox.png"),
      img1: require("../../assets/10.png"),
      img2: require("../../assets/20.png"),
      img3: require("../../assets/30.png"),
      img4: require("../../assets/40.png"),
      img5: require("../../assets/50.png"),
      img6: require("../../assets/60.png"),
      img7: require("../../assets/70.png"),
      img8: require("../../assets/80.png"),
      img9: require("../../assets/90.png"),
      img10: require("../../assets/100.png"),
      img11: require("../../assets/red10.png"),
      img12: require("../../assets/red20.png"),
      img13: require("../../assets/red30.png"),
      img14: require("../../assets/red40.png"),
      img15: require("../../assets/red50.png"),
      img16: require("../../assets/red60.png"),
      img17: require("../../assets/red70.png"),
      img18: require("../../assets/red80.png"),
      img19: require("../../assets/red90.png"),
      img20: require("../../assets/red100.png"),
      remlist: [],
      bai1: "",
      bai2: "",
      sn: "",
      // 定时器
      timer:null
    };
  },
  created() {
    // console.log(this.$store.state.sheid);
    // details(this.$store.state.sheid).then((res) => {
    //   console.log("泵余量");
    //   console.log(res.alarmSettings[0]);
    //   console.log(res);
    //   this.sn = res.details[0].sn;
    //   console.log(this.sn);
    //   this.remlist = res.details[0];
    //   console.log(this.remlist);
    //   if (this.remlist.bsurplus < res.alarmSettings[0].leveldz) {
    //     this.status1 = false;
    //   } else {
    //     this.status1 = true;
    //   }
    //   if (this.remlist.bsurpluss < res.alarmSettings[0].levelsdz) {
    //     this.status2 = false;
    //   } else {
    //     this.status2 = true;
    //   }
    //   this.bai1 = ((this.remlist.bsurplus / this.remlist.pumpll) * 100).toFixed(
    //     2
    //   );
    //   console.log(this.bai1);
    //   this.bai2 = (
    //     (this.remlist.bsurpluss / this.remlist.pumplls) *
    //     100
    //   ).toFixed(2);
    // });
  },
  mounted(){
    this.getDetails()
    this.$nextTick(() => {
      //  定时刷新
      this.timer = setInterval(() => {
        this.getDetails()
      }, 2000)
    })
  },
  methods: {
    // 获取数据
    getDetails(){
      details(this.$store.state.sheid).then((res) => {
      console.log("泵余量");
      console.log(res.alarmSettings[0]);
      console.log(res);
      this.sn = res.details[0].sn;
      console.log(this.sn);
      this.remlist = res.details[0];
      console.log(this.remlist);
      if (this.remlist.bsurplus < res.alarmSettings[0].leveldz) {
        this.status1 = false;
      } else {
        this.status1 = true;
      }
      if (this.remlist.bsurpluss < res.alarmSettings[0].levelsdz) {
        this.status2 = false;
      } else {
        this.status2 = true;
      }
      this.bai1 = ((this.remlist.bsurplus / this.remlist.pumpll) * 100).toFixed(
        2
      );
      console.log(this.bai1);
      this.bai2 = (
        (this.remlist.bsurpluss / this.remlist.pumplls) *
        100
      ).toFixed(2);
    });
    },
    firsure() {
      // 正则表达式
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      // 判定输入是否符合正则表达式
      if (!numRe.test(this.sheding1)) {
        this.$refs.fanwei1.style.color = "red";
      } else {
        if (this.sheding1 > 1000 || this.sheding1 < 0 || this.sheding1 == "") {
          this.$refs.fanwei1.style.color = "red";
        } else {
          let data = {
            sn: this.sn,
            pumpll: this.sheding1,
            type: 2,
          };
          console.log(data);
          deviceEdit(data).then((res) => {
            console.log(res);
            if (res.resutlt == 0) {
              this.shuju(this.$store.state.sheid);
              this.$message({
                message: "恭喜你，设置成功！",
                type: "success",
              });
              this.dialogVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });

          this.$refs.fanwei1.style.color = "#adadad";
        }
      }
    },
    secsure() {
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.sheding2)) {
        this.$refs.fanwei2.style.color = "red";
      } else {
        if (this.sheding2 > 1000 || this.sheding2 < 0) {
          this.$refs.fanwei2.style.color = "red";
        } else {
          let data = {
            sn: this.sn,
            pumplls: this.sheding2,
            type: 4,
          };
          // data.sn = this.sn;
          // data.umpll = this.sheding1;
          // console.log(data);
          deviceEdit(data).then((res) => {
            console.log(res);
            if (res.resutlt == 0) {
              this.shuju(this.$store.state.sheid);
              this.$message({
                message: "恭喜你，设置成功！",
                type: "success",
              });
              this.dialogVisible1 = false;
            } else {
              this.$message.error(res.message);
            }
          });
          this.$refs.fanwei2.style.color = "#adadad";
        }
      }
    },
    // 重启1
    rechong() {
      this.$confirm("此操作将重置泵1的余量, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            sn: this.sn,
            pumplls: this.remlist.breset,
            type: 1,
          };
          deviceEdit(data).then((res) => {
            console.log(res);
            if (res.resutlt == 0) {
              this.shuju(this.$store.state.sheid);
              this.$message({
                message: "重置成功！",
                type: "success",
              });
              this.dialogVisible1 = false;
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    // 重置2
    rechong1() {
      this.$confirm("此操作将重置泵2的余量, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            sn: this.sn,
            pumplls: this.remlist.bresets,
            type: 3,
          };
          deviceEdit(data).then((res) => {
            console.log(res);
            if (res.resutlt == 0) {
              this.shuju(this.$store.state.sheid);
              this.$message({
                message: "重置成功！",
                type: "success",
              });
              this.dialogVisible1 = false;
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    shuju(data) {
      details(data).then((res) => {
        console.log("泵余量");
        console.log(res.alarmSettings[0]);
        console.log(res);
        this.sn = res.details[0].sn;
        console.log(this.sn);
        this.remlist = res.details[0];
        console.log(this.remlist);
        if (this.remlist.bsurplus < res.alarmSettings[0].leveldz) {
          this.status1 = false;
        } else {
          this.status1 = true;
        }
        if (this.remlist.bsurpluss < res.alarmSettings[0].levelsdz) {
          this.status2 = false;
        } else {
          this.status2 = true;
        }
        this.bai1 = (
          (this.remlist.bsurplus / this.remlist.pumpll) *
          100
        ).toFixed(2);

        console.log(this.bai1);
        this.bai2 = (
          (this.remlist.bsurpluss / this.remlist.pumplls) *
          100
        ).toFixed(2);
      });
    },
    // dakai
    open() {
      this.sheding1 = "";
      this.dialogVisible = true;
      if (this.$refs.fanwei1) {
        this.$refs.fanwei1.style.color = "#adadad";
      }
    },
    open1() {
      this.sheding2 = "";
      this.dialogVisible1 = true;
      if (this.$refs.fanwei2) {
        this.$refs.fanwei2.style.color = "#adadad";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.remaining {
  height: 413px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  .first {
    // margin-left: 120px;
    // margin-right: 120px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 288px;
    .newbox {
      width: 268px;
      height: 268px;
      margin-left: 50px;
      position: relative;
      // border: 1px solid red;
      // background-image: url("../../assets/shuibox.png");
      background-size: 100% 100%;
      img {
        width: 100%;
        height: 100%;
      }
      div {
        width: 100%;
        position: absolute;
        text-align: center;
        top: 40%;
        // left: 35%;
        font-size: 42px;
        color: #909399;
      }
    }

    .remtop {
      font-size: 28px;
      display: flex;
      align-items: center;
      /deep/.el-button {
        width: 120px;
        height: 60px;
        font-size: 24px;
        margin-left: 30px;
      }
    }
    .remmain {
      font-size: 24px;
      div {
        height: 36px;
        margin-bottom: 10px;
      }
      .she {
        margin-left: 20px;
        color: rgba(31, 109, 249, 100);
        cursor: pointer;
      }
    }
  }
  .fanwei {
    font-size: 24px;
    color: #adadad;
    margin-left: 20px;
  }
  /deep/.el-dialog__title {
    font-size: 28px;
    font-weight: 600;
  }
  /deep/.el-button {
    font-size: 20px;
  }
  /deep/.el-input__inner {
    font-size: 20px;
  }
  .retbtn {
        width: 100px;
        height: 60px;
        font-size: 20px;
        margin-left: 15px;
        background-color:rgb(86,119,34)
    }
}
</style>
